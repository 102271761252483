
*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}

button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 1rem;
}