.AddPanier{
    display: flex;
    justify-content: space-between;
    border: 1px solid $main-color-fonce;
    padding: 1rem 2rem;
    border-radius: 40px;
    cursor: pointer;
    &_text{
        display: flex;
        gap: 1rem;
    }

    &_valid {
        background-color: $main-color-claire;
        transition: all ease-in-out .5s;
        &:hover{
            background-color: $main-color-fonce;
            & p{
                color: $main-color-claire;
                -webkit-text-fill-color: $main-color-claire;
            } 
        }
    }
}