.OptionDecoration{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
    &_text{
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    &_choice{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        width: 100%;
        &_button{
            padding: 1rem;
            border-radius: 5rem;
            border: solid 1px $main-color-fonce;
            transition: all .25s ease-in-out;
            &:hover{
                background-color: $main-color-claire;
            }
            &_select{
                background-color: $main-color-claire;
            }
        }
    }
}