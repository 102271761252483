.Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 3rem);
    padding: 1rem 1.5rem;
    position: fixed;
    z-index: 1;
    background-color: $blanc;
    animation:apparition_header .5s ease-in-out;
    
    &_menu_desk{
        opacity: 0;
        width: 1rem;
        font-size: 0rem;
        justify-content: flex-end;
    }

    &_logo{

    }
}

#Menu_button_shop{
    order: 3;
}

@media screen and (min-width: 720px) {
    .Header{
        flex-direction: row;
        width: calc(100% - 8rem);
        padding: 1rem 4rem;
        &_menu_desk{
            display: flex;
            opacity: 1;
            width: 200px;
        }

        &_logo{
            
        }
    }
}


@keyframes apparition_header{
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}