.PanierArianeItem{
    display: flex;
    align-items: center;
    gap: .5rem;

    &_index{
        background-color: $main-color-claire;
        color: $blanc;
        -webkit-text-fill-color: $blanc;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
    }

    &_text{
        color: $main-color-claire;
        -webkit-text-fill-color: $main-color-claire;

    }

    &_actif .PanierArianeItem_index{
        background-color: $main-color-fonce;   
    }

    &_actif .PanierArianeItem_text{
        color: $main-color-fonce;   
        -webkit-text-fill-color: $main-color-fonce;

    }
}

@media screen and (max-width: 720px) {
    .PanierArianeItem{
        &_index{
            display: none;
        }
        &_text{
            display: none;
    
        }
    
        &_actif .PanierArianeItem_index{
            display: flex;
            background-color: $main-color-fonce;  
            font-size: 1rem; 
        }
    
        &_actif .PanierArianeItem_text{
            display: flex;
            color: $main-color-fonce;   
            -webkit-text-fill-color: $main-color-fonce;
            font-size: 1rem; 
    
        }
    }

    .PanierArianeItem_actif + .mobile_true{
        display: flex;
    }

    .PanierArianeItem_actif + .mobile_false{
        display: none;
    }
}