.DeleteButton {
    display: flex;
    border: solid $noir 1px;
    border-radius: 1rem;
    padding: 1rem;
    gap: 1rem;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: $main-color-claire;
    }
}

@media screen and (max-width: 720px) {
    .DeleteButton{
        position: absolute;
        left: 80%;

        &_logo{
            object-fit: cover;
            height: 1rem;
        }
    }
}
