.Menu{
    position: relative;
    overflow-x: hidden;
    display: flex;
    &_button{
        font-size: 1.5rem;
        font-weight: 600;
    }
}

.Menu_button_mobil{
    width: 24px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    cursor: pointer;    

    &_ligne {
        background-color: $main-color-fonce;
        border-radius: 5px;
        width: 24px;
        height: 3px;
        transition: all .3s ease-in-out;
        
    }
}

.Menu_button_desk{
    text-align: left;
    display: none;
}

.Menu_button_right{
    text-align: right;
}

.Menu_container{
    position: fixed;
    left: -100%;
    top: 0;
    background-color: $main-color-claire;
    width: 100%;
    height: 100vh; 
    transition: all 0.4s ease-in-out;
    z-index: 5;
    &_open{
        left: 0%;  
        z-index: 5;
    }

    &_hero{
        display: flex;
        flex-direction: column;
        position: relative;
        width: calc(100% - 4rem);
        height: calc(100% - 4rem);
        padding: 2rem 2rem;
        gap: 2rem;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
        &_close{
            position: absolute;
            right: 2rem;
            top: 2rem;
        }

        &_nav{
            display: flex;
        }
    }
}

@media screen and (min-width: 720px) {
    .Menu{
        &_bottom{
            width: 200px;
        }
    }
    
    .Menu_container{

        &_open{

        }
    
        &_hero{
            flex-direction: row;
        }
    }

    .Menu_button_desk{
        display: flex;
    }

    .Menu_button_mobil{
        display: none;
    }
}
