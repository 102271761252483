.ListeProduits{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    padding-bottom: 5rem;
}

@media screen and (max-width: 1520px) {
    .ListeProduits{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        width: 100%;
    }
    
}

@media screen and (max-width: 780px) {
    .ListeProduits{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 3rem;
    }
    
}