.CarrouselProduct{
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    min-width: 100%;
    &_slider{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        transition: all ease-in-out 0.5s;
        z-index: -1;

    }

    &_button{
        position: absolute;
        width: 50%;
        height: 100%;
        z-index: 0;
        &_prec{
            left: 0;
            cursor: url(../../../../assets/utils/cursor_prec.svg), auto;
        }
        &_next{
            right: 0px;
            cursor: url(../../../../assets/utils/cursor_next.svg), auto;
        }
    }

    & img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}