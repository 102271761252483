.Product_information{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: calc(100% - 2rem);
    padding: 0rem 1rem;
    &_header{
        display: flex;
        flex-direction: column;
    }

    &_text{
        display: flex;
        flex-direction: column;
    } 
}