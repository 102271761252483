.ChooseColor {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    &_text{
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    &_choice {
        display: flex;
        gap: 1rem;
        &_button {
            width: 1rem;
            height: 1rem;  
            border-radius: 1rem;
            &_decoration{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1.5px solid $noir;
                width: 1.5rem;
                height: 1.5rem;  
                border-radius: 1rem;
            }
            &_select {
                border: 1px solid $noir;
                &_decoration{
                }
            }
        }
    }
}
