.Therms{
    width: calc(100% - 8rem);
    padding: 10rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_section{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        max-width: 1000px;
        width: 100%;
        & p{
            text-align: justify;
            line-height: 1.5rem;
        }
        &_text{
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 1.5rem; 
            &_paragraphe{
                display: flex;
                flex-direction: column;
                gap: .5rem;
            }
            
        }
    }
}