*{
    font-family: 'Montserrat', sans-serif;
}

h1{
    font-size: 2.5rem;
    color: $noir;
    font-family: 'Vollkorn', serif;
}

h2{
    font-size: 2rem;
    color: $noir;
    font-family: 'Vollkorn', serif;
}

h3{
    font-size: 1.5rem;
    color: $noir; 
}

p{
    font-size: 1.2rem;
    color: $noir;
    -webkit-text-fill-color: $noir;

}

li{
    font-size: 2rem;
    color: $main-color-fonce;
    -webkit-text-fill-color: $main-color-fonce;

}

a{
    color: $main-color-fonce;
    -webkit-text-fill-color: $main-color-fonce;
}

label{
    font-size: 1.2rem;
    color: $noir;
    -webkit-text-fill-color: $noir;
}

input{
    padding: .5rem .5rem;
    font-size: 1.2rem;
    color: $noir;
    -webkit-text-fill-color: $noir;
}

select{
    padding: .5rem .5rem;
    font-size: 1.2rem;
    color: $noir;
    -webkit-text-fill-color: $noir;
}


@media screen and (max-width: 720px) {
    h1{
        font-size: 1.75rem;
    }
    li{
        font-size: 1.2rem;
    }
}