.CarteCarrousel{
    &_image{
        width: 360px;
        height: 400px;
        object-fit: cover;
    }

    &_text{
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
}


@media screen and (max-width: 500px) {
    .CarteCarrousel{
        &_image{
            width: 250px;
            height: 300px;
            object-fit: cover;
        }
    }
}