.CompteurPanier{
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-color-fonce;
    color: $blanc;
    -webkit-text-fill-color: $blanc;
    border-radius: 4rem;
}   