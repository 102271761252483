.FiltreItems {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

@media screen and (max-width: 720px) {
    .FiltreItems {
        display: none;
    }
}
