.Tag{
    display: flex;
    padding: .6rem 1.5rem;
    width: fit-content;
    border-radius: 2rem;
    border: 1px solid $main-color-fonce;
    background-color: $main-color-claire;
    transition: all .5s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: $main-color-fonce;

        & p{
            color: $main-color-claire;
            -webkit-text-fill-color: $main-color-claire;
            
        }
    }
}