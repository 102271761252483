.Commande {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 6rem;
    &_container {
        max-width: 1440px;
        width: calc(100% - 8rem);
        padding: 0rem 4rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    &_form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_section {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            border: none;
            border-radius: 2rem;

            &_sousSection {
                display: flex;
                gap: 1.5rem;
            }
        }

        &_buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 2rem 0rem;
        }
    }
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    &_label {
    }

    & input {
    }
}

.react-tel-input {
    width: 100%;
}

.react-tel-input .form-control {
    width: 100%;
    border-color: 1px $noir solid;
}

.test {
    width: 100%;
}

@media screen and (max-width: 720px) {
    .Commande {
        &_container {
            width: calc(100% - 2rem);
            padding: 0rem 1rem;
        }

        &_form {
            &_section {
                gap: 1rem;
                &_sousSection {
                    flex-direction: column;
                    gap: 0.5rem;
                }
            }

            &_buttons {
                flex-direction: column;
                gap: 1rem;
            }
        }
    }
}
