.Product{
    display: flex;
    flex-direction: column  ;
    padding-top: 10rem;
    padding-bottom: 10rem;
    gap: 10rem;
    overflow: hidden;
    
    &_hero{
        display: flex;
        gap: 3rem;
        padding: 0rem 3rem;

        &_container{
            display: flex;
            flex-direction: column;
            gap: 3rem;
        }
    }

    &_carrousel{
        width: 100%;
        overflow: hidden;
    }

    &_car{
        display: flex;
        max-width: 1550px;
    }

    &_information{
        width: 100%;
    }

    &_section{
        display: flex;
        width: 100%;
        height: 90vh;
        align-items: center;
        background-color: $main-color-claire;
        overflow: hidden;
        &_text{
            width: calc(100% - 10rem);
            padding: 5rem;

            & p{
                width: 100%;
                height: 100%;
                font-size: 2.5rem;
                color: $main-color-fonce;
                font-family: 'Vollkorn', serif;
            }
        }

        &_image{
            width: 50%;
            & img{
                object-fit: cover;
                width: 50%;
            }
        }
    }
}


@media screen and (max-width: 1100px) {
    .Product{
        &_car{
            display: flex;
            flex-direction: column;
        }
    }
}


@media screen and (max-width: 720px) {
    .Product{
        display: flex;
        flex-direction: column  ;
        padding-top: 10rem;
        padding-bottom: 10rem;
        gap: 10rem;
        overflow: hidden;
        &_hero{
            display: flex;
            gap: 3rem;
            padding: 0rem 0rem;
    
            &_container{
                display: flex;
                flex-direction: column;
                gap: 3rem;
            }
        }
    
        &_carrousel{
            width: 100%;
            overflow: hidden;
        }
    
        &_car{
            display: flex;
        }
    
        &_information{
            width: 100%;
        }
    
        &_section{
            display: flex;
            width: 100%;
            height: 90vh;
            align-items: center;
            background-color: $main-color-claire;
            overflow: hidden;
            &_text{
                width: calc(100% - 10rem);
                padding: 5rem;
                & p{
                    width: 100%;
                    height: 100%;
                    font-size: 2.5rem;
                    color: $main-color-fonce;
                    font-family: 'Vollkorn', serif;
                }
            }
    
            &_image{
                width: 50%;
                & img{
                    object-fit: cover;
                    width: 50%;
                }
            }
        }
    }
}