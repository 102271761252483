.Contact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 10rem);
    padding: 0rem 5rem;
    height: 95vh;
    padding-top: 3rem;
    &_container{
        display: flex;
        width: 100%;
        max-width: 1440px;
        height: 45rem;
    }

    &_image{
        width: 100%; 
        & img{
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-radius: 2rem 0rem 0rem 2rem;
        }
    }

    &_text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5rem;
        width: calc(100% - 8rem);
        height: 100%;
        padding: 0rem 4rem;
        border-radius: 0rem 2rem 2rem 0rem;
        background-color: $main-color-claire;
        &_liste{
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }
    }
}

@media screen and (min-width: 1100px) {
    .Contact {

        &_container{

        }

    
        &_text{

            &_liste{

            }
        }
    }
}

@media screen and (max-width: 720px) {
    .Contact {
        width: calc(100% - 2rem);
        padding: 6rem 1rem;
        height: auto;
        &_container{
            flex-direction: column-reverse;
            
        }

        &_image{
            & img{
                height: 100%;
                border-radius: 0rem 0rem 2rem 2rem;
            }
        }

        &_text{
            width: calc(100% - 2rem);
            padding: 0rem 1rem;
            border-radius: 2rem 2rem 0rem 0rem;
            background-color: $main-color-claire;
        }
    }
}

