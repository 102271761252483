.Summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 6rem 0rem;
    gap: 2rem;
    &_container {
        max-width: 1440px;
        width: calc(100% - 8rem);
        padding: 0rem 4rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &_layout{
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }

        &_article{
            display: flex;
            gap: 2rem;
            width: 100%;
            & article{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                width: 70%;
            }
        }
    }

    &_aside{
        display: flex;
        padding-top: 1rem;
        flex-direction: column;
        width: 50%;
        gap: 2rem;
        &_text{
            padding: 2rem;
            background-color: $main-color-claire;
            height: fit-content;
            border-radius: 1rem;
        }
    }

    &_buttons{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &_panier{
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 720px) {
    .Summary {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 6rem 0rem;
        gap: 2rem;
        &_container {
            max-width: 1440px;
            width: calc(100% - 2rem);
            padding: 0rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
    
            &_layout{
                display: flex;
                flex-direction: column;
                gap: .5rem;
            }
    
            &_article{
                display: flex;
                flex-direction: column;
                gap: 2rem;
                width: 100%;
                & article{
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;
                    width: 100%;
                }
            }
        }

        &_panier{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        &_aside{
            width: 100%;
        }
    }
}