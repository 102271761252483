.Footer{
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    background-color: $main-color-claire;
    padding: 4rem 1rem;
    gap: 2rem;
    &_header{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_main{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    &_navigation{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (min-width: 720px) {
    .Footer{
        width: calc(100% - 8rem);
        padding: 5rem 4rem;
    }
}

@media screen and (min-width: 1100px) {
    .Footer{
        gap: 4rem;
        &_header{
            display: flex;
            flex-direction: row;
            gap: 0;
            justify-content: space-between;

            &_text{
                width: 50%;
            }

            &_main{
                flex-direction: row;
                width: 100%;
            }
        }
        &_navigation{
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 18%;
            justify-content: flex-end;
        }
    }
}