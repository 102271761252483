.QuantityButton{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    &_text{
        display: flex;
        gap: .5rem;
    }

    &_compteur{
        display: flex;
        font-size: 1rem;
        width: fit-content;
        border-radius: 2rem;
        &_button{
            padding: 1rem;
            border: none;
            transition: all .25s ease-in-out;
            &:hover{
                background-color: $main-color-fonce;
                color: $main-color-claire;
                -webkit-text-fill-color: $main-color-claire;

            }
        }

        &_input{
            width: 2rem;
            border: none;
            border-top: 1px solid $main-color-fonce;
            border-bottom: 1px solid $main-color-fonce;
            background-color: transparent;
            padding: 1rem;
            width: 3rem;
            text-align: center;
            font-size: 1rem;
            font-family: 'Montserrat', sans-serif;
        }
    }

    &_valid > .QuantityButton{
        &_compteur{
            background-color: $main-color-claire;
            
            &_button{
                background-color: $main-color-claire;
            }
        }
    }
}

#QuantityButton_compteur_button_left{
    border-left: 1px solid $main-color-fonce;
    border-top: 1px solid $main-color-fonce;
    border-bottom: 1px solid $main-color-fonce;
    border-radius: 2rem 0rem 0rem 2rem;
}

#QuantityButton_compteur_button_right{
    border-right: 1px solid $main-color-fonce;
    border-top: 1px solid $main-color-fonce;
    border-bottom: 1px solid $main-color-fonce;
    border-radius: 0rem 2rem 2rem 0rem;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }