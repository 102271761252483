.FiltreItemsMobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0px;
    z-index: 5;
    opacity: 0;
    transition: all .25s ease-in-out;
    gap: 1rem;
    &_button{
        position: relative;
        border: 1px solid $main-color-fonce;
        border-radius: 10px;
        padding: 1rem;
        z-index: 5;
        width: 100%;
        &:hover{
            background-color: $main-color-fonce;
            color: $main-color-claire;
            -webkit-text-fill-color: $main-color-claire;

        }
    }

    &_actif{
        opacity: 1;
        height: 60vh;
        max-height: 100vh;
        width: 100%;
        padding-bottom: 1.5rem;
    }

    &_liste{
        display: flex;
        flex-direction: column;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
        gap: .25rem;
    }
    
}

@media screen and (min-width: 720px) {
    .FiltreItemsMobile {
        display: none;
        &_button{
            display: none;
        }
    }
}


