.Ariane{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    &_button{
        font-size: 2.5rem;
        color: $gris;
        -webkit-text-fill-color: $gris;

        font-weight: 500;

        &:hover{
            color: $main-color-fonce;
            -webkit-text-fill-color: $main-color-fonce;

        }
    }

    &_titre{
        font-family: 'Montserrat', sans-serif;
        font-size: 2.5rem;
        color: $gris;
        -webkit-text-fill-color: $gris;
        font-weight: 500;
    }
}

@media screen and (max-width: 1100px) {
    .Ariane{
        &_button{
            font-size: 1.5rem;
        }
        &_titre{
            font-size: 1.5rem;
        }
    }
}

@media screen and (max-width: 720px) {
    .Ariane{
        width: calc(100% - 2rem);
        padding: 0rem 1rem;
        &_button{
            font-size: 1.5rem;
            color: $gris;
            -webkit-text-fill-color: $gris;
    
            font-weight: 500;
    
            &:hover{
                color: $main-color-fonce;
                -webkit-text-fill-color: $main-color-fonce;
    
            }
        }
    
        &_titre{
            font-family: 'Montserrat', sans-serif;
            font-size: 1.5rem;
            color: $gris;
            -webkit-text-fill-color: $gris;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 500px) {
    .Ariane{
        &_button{
            font-size: 1rem;
        }
        &_titre{
            font-size: 1rem;
        }
    }
}