.CartePanierMinimaliste{
    display: flex;
    border: solid $noir 1px;
    border-radius: 1rem;
    padding: .5rem 1rem;
    gap: 1rem;
    &_image{
        width: 8rem;
        object-fit: cover;
    }

    &_header{
        padding-top: .5rem;
    }
}

@media screen and (max-width: 720px) {
    .CartePanierMinimaliste{
        display: flex;
        width: calc(100% - 2rem);
        border: solid $noir 1px;
        border-radius: 1rem;
        padding: .5rem 1rem;
        gap: 1rem;
        &_image{
            width: 8rem;
            object-fit: cover;
        }
    
        &_header{
            padding-top: .5rem;
        }
    }
}