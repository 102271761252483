.MenuItem{
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    width: fit-content;
    &_ligne{
        width: 0px;
        height: 2px;
        transition: all .2s ease-in-out;
        
        
    }

    &:hover > .MenuItem_ligne{
        width: 100%;
        background-color: $main-color-fonce;
    }
}

