.HeaderHome{
    width: 100%;
    height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 5rem;
    & img{
        width: 100%;    
        height: 90vh;
        object-fit: cover;
    }

    &_button{
        background-color: $main-color-fonce;
        padding: 1.5rem 3rem;
        border-radius: 3rem;
        font-weight: 600;
        color: $blanc;
        -webkit-text-fill-color: $blanc;

        position: absolute;
        border: solid 1px $main-color-fonce;
        bottom: 5rem;
        transition: all .25s ease-in-out;
    }
}

@media screen and (min-width: 720px) {
    .HeaderHome{
        &_button{
            display: none;
        }
    }
}