$main-color-fonce: #143601;
$main-color-claire: #DBE4CA;
$blanc: #FFF;
$noir: #0C0D0D;
$gris: #53544F;


//Padding
$paddingContent_mobile: 1rem;
$paddingContent_tablette: 1rem;
$paddingContent_desk: 1rem;