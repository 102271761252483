.CarteProduits{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 350px;
    width: 100%;
    &_text{
        display: flex;
        flex-direction: column;
        gap: 0rem;
    }

    &_listeTag{
        display: flex;
        gap: 1rem;
    }

    & img{
        max-width: 350px;
        width: 100%;
    }
}