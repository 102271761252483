.UploadButton{
    display: flex;
    border: solid $noir 1px;
    border-radius: 1rem;
    padding: 1rem 2rem;
    gap: 1rem;
    transition: all .5s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: $main-color-claire;
    }
}