.PanierAriane{
    display: flex;
    justify-content: space-between;
    border: 1px solid $noir;
    padding: 1rem 2rem;
    border-radius: 1rem;

    &_cote{
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

@media screen and (max-width: 720px) {
    .PanierAriane{
        display: flex;
        justify-content: space-between;
        border: 1px solid $noir;
        padding: .5rem 1rem;
        border-radius: 1rem;
    
        &_cote{
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    }
    
}