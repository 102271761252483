.CommandeValidate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    width: 100%;
    height: 95vh;

    &_img {
        width: 10rem;
        height: 10rem;
    }

    &_text {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 33%;
    }

    &_button {
        border-radius: 10rem;
        padding: 1rem 10rem;
        background-color: $main-color-claire;
        transition: all 0.5s ease-in-out;
        &:hover {
            background-color: $main-color-fonce;
            color: $main-color-claire;
        }
    }
}
