.CartePanier {
    display: flex;
    padding: 1rem 1rem;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 1rem;
    border: 1px solid $main-color-fonce;
    height: 16vh;

    &_header {
        display: flex;
        width: 100%;
    }

    &_information {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        width: 70%;
        &_color {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            &_span {
                border: 1px solid black;
                width: 1rem;
                height: 1rem;
                border-radius: 100%;
            }
        }

        &_header {
            display: flex;
            flex-direction: column;
        }
    }

    &_quantity {
        padding: 0.5rem 1rem;
        & .QuantityButton_text {
            display: none;
        }
    }

    &_buttons {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 1rem;
    }

    &_image {
        object-fit: cover;
        width: 10rem;
        height: 10rem;
    }
}

@media screen and (max-width: 720px) {
    .CartePanier {
        position: relative;
        padding: 1rem 1rem;
        align-items: flex-end;
        gap: 1.5rem;
        border-radius: 1rem;
        border: 1px solid $main-color-fonce;
        height: auto;

        &_header {
            display: flex;
            width: 100%;

            & h2{
                font-size: 1.5rem;
                text-overflow: ellipsis;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &_information {
            display: flex;
            gap: 1rem;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            width: 70%;
            &_color {
                display: flex;
                align-items: center;
                gap: 0.25rem;
                & p{
                    font-size: 1rem;
                }
                &_span {
                    border: 1px solid black;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 100%;
                }
            }

            &_header {
                display: flex;
                flex-direction: column;
            }

            &_duree{
                font-size: 1rem;
            }
        }

        &_quantity {
            padding: 0.5rem 1rem;
            & .QuantityButton_text {
                display: none;
            }
        }

        &_buttons {
            width: 60%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 1rem;
        }

        &_image {
            object-fit: cover;
            width: 5rem;
            height: 5rem;
        }
    }
}
