.ContainerHome{
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    padding: 0rem 1rem;
    gap: 2rem;
    &_texts{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1rem;

        &_text{
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: .5rem;

            & p{ 
                text-align: justify;
                line-height: 1.5rem;
            }
        }
    }

    &_button{
        background-color: #143601;
        padding: 1.5rem 2rem;
        border-radius: 3rem;
        font-weight: 600;
        color: #FFF;
        -webkit-text-fill-color: #FFF;
        border: solid 1px #143601;
        transition: all 0.25s ease-in-out;
        text-align: center;

        &_invisible{
            display: none;
        }
    }
    & img{ 
        width: 100%;
        object-fit: cover;
    }
}

.imgArondi{
    & img{
    border-radius: 2rem;
    }
}

@media screen and (min-width: 720px) {
    .ContainerHome{
        flex-direction: row-reverse;
        align-items: center;
        width: calc(100% - 6rem);
        padding: 0rem 3rem;
        gap: 4rem;
        &_texts{
            width: 50%;
            gap: 3rem;
            &_text{
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 1rem;
            }
        }
        & img{ 
            width: 50%;
            height: 45rem;
            object-fit: cover;
        }
    }

    .imgArondi{
        flex-direction: row;

        & img{
            border-radius: 10rem;
        }
    }
}

@media screen and (min-width: 1100px) {
    .ContainerHome{
        max-width: 1440px;
        gap: 10rem;
    }
}