.FiltreItem{
    display: flex;
    width: 100%;
    transition: all .5s ease-in-out;

    &_text{
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: flex-start;
        transition: all .1s ease;
    }

    &_line{
        width: 0px;
        height: 1px;
        background-color: $main-color-fonce;
        transition: all .1s ease-in-out;
    }   

    &_number{
        transition: all .5s ease-in-out;
        font-size: .8rem;
        
    }

    &_principal button{
        font-weight: 700;
    }

    &:hover .FiltreItem_text{
        width: 100%;
    }

    &:hover .FiltreItem_line{
        width: 100%;
    }
}

@media screen and (max-width: 720px) {
    .FiltreItem_button{
        font-size: 1.5rem;
    }

    .FiltreItem_principal{
        padding-top: 1rem;
    }

}