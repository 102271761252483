.Home{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8rem;
    width: 100%;
    padding-bottom: 8rem;

    & h2{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 500px) {
    .Home{
        gap: 5rem;
    }
}