.Manifeste{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: calc(100% - 10rem);
    padding: 10rem 5rem;
    background-color: $main-color-claire;
    &_ligne{
        display: flex;
        align-items: center;
        gap: 1rem;
        &_num{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            border: 1px solid $noir;
            border-radius: 100rem;
            & p{
                font-size: 1.5rem;
            }
        }

        &_text{
            font-size: 1.5rem;
        }
    }
}