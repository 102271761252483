.CarrouselHome {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    padding: 0rem 1rem;
    gap: 2rem;

    &_container{

    }
    
    &_carrousel {
        display: flex;
        overflow-x: scroll;
        gap: 3rem;
        padding-bottom: 3rem;
    }
}

@media screen and (min-width: 720px) {
    .CarrouselHome {
        width: calc(100% - 6rem);
        padding: 0rem 3rem;
        gap: 4rem;
    }
}

@media screen and (min-width: 1100px) {
    .CarrouselHome {
        max-width: 1440px;
        gap: 5rem;
    }
}

/* input */
.CarrouselHome_carrousel {
    scrollbar-color: #143601 $main-color-claire;
    scrollbar-width: thin;
    
    
}
/* output */
.CarrouselHome_carrousel::-webkit-scrollbar-thumb {
    background-color: #143601;
    border-radius: 10px;
}
.CarrouselHome_carrousel::-webkit-scrollbar-track {
    background-color: $main-color-claire;
    border-radius: 10px;
    
    
}
.CarrouselHome_carrousel::-webkit-scrollbar-corner {
    background-color: $main-color-claire;
}
.CarrouselHome_carrousel::-webkit-scrollbar {
    width: 0.30rem;
    height: 0.30rem;
}
.CarrouselHome_carrousel {
    -ms-overflow-style: auto;
    scrollbar-color: #143601 $main-color-claire;
    scrollbar-width: thin;
}
