.Panier {
    display: flex;
    position: relative;
    &_buttons {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        justify-content: flex-end;
        &_button {
            font-size: 1.5rem;
            font-weight: 600;
            &_desk {
                display: none;
            }
            &_mobile {
                display: flex;
            }
        }
    }

    &_container { 
        position: fixed;
        padding: 4rem;
        left: 100%;
        top: 0;
        background-color: $blanc;
        width: calc(100vw - 8rem);
        min-height: 100vh;
        transition: all 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        z-index: 10;
        &_open {
            left: 0%;
        }

        &_listeProduit {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            max-height: 60vh;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &_buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &_button {
                border: 1px solid $noir;
                padding: 1rem;
                border-radius: 1rem;
                font-weight: 600;
                font-size: 1.25rem;
                transition: all 0.5s ease-in-out;
                &:hover {
                    background-color: $main-color-claire;
                }
            }
        }
    }
}

#Button_Commande {
    background-color: $main-color-claire;
    color: $main-color-fonce;
    -webkit-text-fill-color: $main-color-fonce;

    &:hover {
        background-color: $main-color-fonce;
        color: $main-color-claire;
        -webkit-text-fill-color: $main-color-claire;
    }
}

@media screen and (max-width: 720px) {
    .Panier {
        &_container {
            width: calc(100vw - 2rem);
            padding: 3rem 1rem;
            &_listeProduit {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                overflow-y: scroll;
                height: calc(100%);
                padding-bottom: 50rem;
                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            &_buttons {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                position: fixed;
                bottom: 0px;
                width: calc(100vw - 2rem);
                padding-bottom: 1rem;
                background-color: $blanc;
                &_button {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 720px) {
    .Panier {
        &_buttons {
            width: 200px;
            &_button {
                &_desk {
                    display: flex;
                }
                &_mobile {
                    
                }
            }
        }
    }
}
