.Shop{
    padding-top: 8rem;
    display: flex;
    width: 100%;
    overflow: hidden;
    min-height: 80vh;
    &_left{
        display: flex;
        padding: 0rem 3rem;

        &_filtreItems{
            width: 100%;
            padding: 3rem 2rem;
            background-color: $main-color-claire;
            border-radius: 20px;
            width: 7rem;
            height: fit-content;
        }
    }

    &_right{
        display: flex;
        flex-direction: column;
        padding-right: 3rem;
        gap: 3rem;
        width: calc(100% - 3rem);
    }
}

@media screen and (max-width: 720px) {
    .Shop{
        padding-top: 8rem;
        display: flex;
        width: 100%;
        overflow: hidden;
        min-height: 80vh;
        &_left{
            padding: 0rem 0rem;
            &_filtreItems{
                position: fixed;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                width: calc(100% - 6rem);
                padding: 1.5rem 3rem;
                border-radius: 1rem 1rem 0px 0px;
                height: fit-content;
                bottom: 0px;
                z-index: 0;
                animation: apparition .5s ease-in-out;
                transition: all .5s ease-in-out;

            }
        }

        &_right{
            display: flex;
            flex-direction: column;
            padding-right: 0rem;
            padding: 1rem 1rem;
            gap: 2rem;
            width: calc(100% - 2rem);
        }
    }
}

@keyframes apparition{
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}