.Shipping {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 6rem;

    &_container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1440px;
        min-height: 80vh;
        gap: 3rem;

        &_layout{
            display: flex;
            width: 100%;
        }

        &_resume{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%; 
            background-color: $main-color-claire;
            border-radius: 1rem;
            padding: 2rem 2rem;
        }

        &_modal{
            display: flex;
            visibility: hidden;
            opacity: 0;
            width: 100%;
            padding: 1rem 2rem;
            transition: all 0.25s ease-in-out;
            &_open{
                visibility: visible;
                opacity: 1;

                width: 100%;
            }

            &_form{
                border: none;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .Shipping {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 6rem;
        &_container{
            display: flex;
            flex-direction: column;
            width: calc(100% - 2rem);
            padding: 0rem 1rem;
            max-width: 1440px;
            gap: 1.5rem;
    
            &_layout{
                display: flex;
                flex-direction: column;
                width: 100%;
            }
    
            &_resume{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: calc(100% - 4rem); 
                background-color: $main-color-claire;
                border-radius: 1rem;
                padding: 2rem 2rem;
            }
    
            &_modal{
                display: flex;
                visibility: hidden;
                opacity: 0;
                width: 100%;
                padding:1rem 0rem;
                transition: all 0.25s ease-in-out;
                &_open{
                    visibility: visible;
                    opacity: 1;
                    width: 100%;
                }
    
                &_form{
                    border: none;
                    width: 100%;
                }
            }
        }
    }
}
