.DropDown{
    cursor: pointer;
    transition: all .25s ease-in-out;
    border-bottom: 1px solid $main-color-fonce;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.25rem;
    &_titre{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_text{
        opacity: 1;
        width: 100%;
        display: none;
        height: 0;
        transition: all .25s ease-in-out;
        animation-name: textAparition;
        animation-duration: 0.5s;
    }   

    &_open{
        
    }

    &_open > .DropDown_text{
        opacity: 1;
        height: fit-content;
        display: flex;
    }
}

@keyframes textAparition {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}